import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const EschAuweiler: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.EschAuweiler,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Esch/Auweiler"
          d="M236.8,425.9l0.3-0.8c-3-1.7-7.7-6.1-11.4-6.7c-0.3-0.5-1.7-2.2-1.8-2.7c-0.2-0.2,0-9,0-9.5 c-1-2.5-3.6-8.4-5.5-10.3c-1.7-3.9-4.5-14-6.3-17.9l16.1-18.7c0.8,0.5,8.8,1.9,9.9,2.1c-4.7-7.3-14.9-22.9-19.7-29.9 c4.2-2.5,5.3-3.4,10.4-3.3c-1-0.3-15.3-22.3-16.3-23.6l-3.9-0.6c6.8-9.3,15-22.2,21.2-31.9l-0.1-0.1H254l65.7,71.3L319,344l-16,8 l4,14l-30,26l14.9,14.9L261,436C260.7,435.9,237,426,236.8,425.9z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 247.874 335.3536)"
        >
          <tspan x="0" y="0">
            Esch/
          </tspan>
          <tspan x="0" y="14.4">
            Auweiler
          </tspan>
        </text>
      </g>
    </>
  );
});
